import PopoverHover from "./popover";

export default function PopoverAPY({title, timespan}) {
   return (
      <PopoverHover title={title}>
         <div className="w-40 lg:w-60 space-y-2">
            <p>
               Instead of APY, DefiPlaza uses something we call Annualized liquidity return (ALR). ALR is a metric that measures the difference in supplying liquidity into a certain pool with
               respect to just holding the token for that pool.
            </p>
            <p>{timespan}</p>
            <p>
               <a href="https://docs.defiplaza.net/defiplaza/radix/faq#how-is-the-apy-calculated" target="blank" className="underline">
                  Learn more
               </a>
            </p>
         </div>
      </PopoverHover>
   );
}